import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import RichText from '@/atoms/RichText';
import Grid from '@/atoms/Grid';
import WithArrow from '@/atoms/WithArrow';
import Head from '@/components/Head';
import Footer from '@/components/Footer';
import PageContainer from '@/components/PageContainer';

import { LOCATIONS } from '@/utils/enums';
import { getUri } from '@/utils/routing';
import theme from '@/styles/theme';

const JobsList = styled(Text).attrs({ as: 'ul' })`
  list-style: none;
`;

export default ({
  data: {
    job: {
      title,
      meta,
      location,
      content,
      sidebarTitle,
      sidebarText,
      relatedJobs,
    },
  },
}) => {
  const titleWithLocation = `${title}, ${LOCATIONS[location].short} Office`;

  return (
    <PageContainer>
      <Head title={titleWithLocation} meta={meta} />

      <Grid.Container>
        <Heading h={2} as="h1" mb={theme.spacing.medium}>
          {titleWithLocation}
        </Heading>

        <Grid mb={theme.spacing.large}>
          <Grid.Item w={[10 / 12, 10 / 12, 8 / 12, 7 / 12]}>
            {content && <RichText blocksRaw={content.textRaw} />}
          </Grid.Item>

          <Grid.Item
            w={[10 / 12, 10 / 12, 3 / 12, 3 / 12]}
            o={[0, 0, 1 / 12, 2 / 12]}
            mt={[theme.spacing.large[0], theme.spacing.large[1], 0]}
          >
            {sidebarTitle && (
              <Text t={1} as="h4" pb={1}>
                {sidebarTitle}
              </Text>
            )}

            {sidebarText && (
              <RichText
                blocksRaw={sidebarText.textRaw}
                $color="gray"
                $maxWidth={18}
                mb={theme.spacing.medium}
              />
            )}

            {relatedJobs.length > 0 && (
              <>
                <Text t={1} as="h4" pb={1}>
                  Other open positions
                </Text>

                <JobsList $color="gray">
                  {relatedJobs.map(job => (
                    <li key={job.slug.current}>
                      <Link to={getUri(job.slug.current, 'job')}>
                        <WithArrow>
                          {job.title}, {LOCATIONS[job.location].short}
                        </WithArrow>
                      </Link>
                    </li>
                  ))}
                </JobsList>
              </>
            )}
          </Grid.Item>
        </Grid>
      </Grid.Container>

      <Footer />
    </PageContainer>
  );
};

export const query = graphql`
  query($id: String!) {
    job: sanityJob(id: { eq: $id }) {
      title
      location
      content {
        textRaw: _rawText
      }
      sidebarTitle
      sidebarText {
        textRaw: _rawText
      }
      relatedJobs {
        key: _id
        title
        location
        slug {
          current
        }
      }
      meta {
        ...metaData
      }
    }
  }
`;
